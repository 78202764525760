<template>
    <div class="grid lg:grid-cols-1 min-h-screen bg-bgGrey">
       <!-- <div class="hidden lg:block" :style="{ backgroundImage: 'url('+ require(`../assets/images/cofeeWoman.png`) + ')', backgroundSize: 'cover', backgroundPosition: 'center' }"></div> -->
       <div class="lg:col-span-auto">
           <div class="bg-white shadow-sm p-4 lg:p-6 flex items-center justify-center space-x-5 lg:space-x-6">
               <router-link to="/"><svgicon name="bni" class="w-16 md:w-20 h-16 md:h-72"></svgicon></router-link>
               <span class="font-bold text-sm md:text-lg">Bienvenue sur la plateforme de collecte de donnée clients de la Banque Nationale d’investissement</span>
           </div>

           <div class="">
               <router-view></router-view>
           </div>
       </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
    components: {

    },
    computed: {
        
    },
    methods: {
        
    },
}
</script>

<style scoped>

</style>